// normalize CSS across browsers
require("./src/normalize.css")
// custom CSS styles
require("./src/style.css")

// Highlighting for code blocks
// import "prismjs/themes/prism.css"

function initGTM() {
  if (window.isGTMLoaded) {
    return false
  }

  window.isGTMLoaded = true

  const script = document.createElement("script")

  script.type = "text/javascript"
  script.async = true
  script.src = `https://www.googletagmanager.com/gtm/js?id=GTM-T5HNJRN`

  script.onload = () => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }
    gtag("js", new Date())

    gtag("config", `GTM-T5HNJRN}`)
  }

  document.head.appendChild(script)
}

function loadGTM(event) {
  initGTM()
  event.currentTarget.removeEventListener(event.type, loadGTM)
}

exports.onClientEntry = () => {
  if (window !== "undefined") {
    document.onreadystatechange = () => {
      if (document.readyState !== "loading") {
        setTimeout(initGTM, 3000)
      }
    }

    document.addEventListener("scroll", loadGTM)
    document.addEventListener("mousemove", loadGTM)
    document.addEventListener("touchstart", loadGTM)
  }
}
